.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(90deg, rgba(2,11,22,1) 0%, rgba(7,33,66,1) 100%);

    &__title {
      font-size: var(--font-size1);
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
    }
    &__text {
      font-size: var(--font-size-lead-text);
      text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    }
}

.neonText {
    animation: flicker 1.5s infinite alternate;
    color: #fff;
  }
  
  @keyframes flicker {
      
    0%, 18%, 22%, 25%, 53%, 57%, 100% {
  
        text-shadow:
        0 0 4px #fff,
        0 0 11px #fff,
        0 0 19px #fff,
        0 0 40px #FF4D5A,
        0 0 80px #FF4D5A,
        0 0 90px #FF4D5A,
        0 0 100px #FF4D5A,
        0 0 150px #FF4D5A;
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
    }    
  }