.scroll-down-text {
    display: flex;
    align-items: center;
    z-index: 1;
    position: fixed;
    left: 2.5vw;
    bottom: 0;
    writing-mode: vertical-rl;
    font-size: 14px;
    color: var(--color-white);
    transition: transform 0.4s ease-in-out;

    &::after {
        content: "";
        display: flex;
        margin: 10px 4px 0 0;
        width: 2px;
        height: 5vh;
        background-color: var(--color-white);
    }
}

.scroll-down-text_show {
    transform: translateY(0);
}
.scroll-down-text_hide {
    transform: translateY(500px);
}

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {

    .scroll-down-text {
        left: 3vw;
    }
}

@media (min-width: 769px) and (max-width: 992px) {

    .scroll-down-text {
        left: 3vw;
    }
}

@media (min-width: 577px) and (max-width: 768px) {

    .scroll-down-text {
        font-size: 12px;
        left: 4vw;

        &::after {
            width: 1px;
        }
    }
}
  
@media (min-width: 0px) and (max-width: 576px) {

    .scroll-down-text {
        font-size: 10px;
        left: auto;
        right: 4vw;
        
        &::after {
            width: 1px;
        }
    }
}

// mobile end