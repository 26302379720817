.shutter {
    position: absolute;
    top: 0;
    bottom:0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #020b16;
    z-index: 6;
    transform: translateY(-100vh);

    &_show {
        transform: translateY(0);
    }

    &_hide {
        transition: transform 0.5s ease-in-out;
        transform: translateY(-100vh);
    }
}