.menu-bar__item {
        width: 24px;
        height: 3px;
        font-size: 0;
        margin: 2vh 0;
        background-color: var(--color-white);
        cursor: pointer;
        transition: all 0.2s ease;

        &:first-child {
            margin: 0 0 2vh 0;
        }
        &:last-child {
            margin: 2vh 0 0 0;
        }

        &_active{
            width: 40px;
            transition: all 0.2s ease;
        }
}

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {
    .menu-bar__item {
        width: 3px;
        height: 24px;
        margin: 0 2vw;

        &:first-child {
            margin: 0 2vw 0 0;
        }

        &:last-child {
            margin: 0 0 0 2vw;
        }

        &_active {
            height: 40px;
            transition: all 0.2s ease-in;
        }
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .menu-bar__item {
        width: 3px;
        height: 24px;
        margin: 0 2vw;

        &:first-child {
            margin: 0 2vw 0 0;
        }

        &:last-child {
            margin: 0 0 0 2vw;
        }

        &_active{
            height: 40px;
            transition: all 0.2s ease-in;
        }
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .menu-bar__item {
        width: 3px;
        height: 24px;
        margin: 0 2vw;

        &:first-child {
            margin: 0 2vw 0 0;
        }

        &:last-child {
            margin: 0 0 0 2vw;
        }
        
        &_active{
            height: 40px;
            transition: all 0.2s ease-in;
        }
    }
}
  
@media (min-width: 0px) and (max-width: 576px) {
    .menu-bar__item {
        width: 3px;
        height: 16px;
        margin: 0 2vw;

        &:first-child {
            margin: 0 2vw 0 0;
        }

        &:last-child {
            margin: 0 0 0 2vw;
        }

        &_active{
            height: 36px;
            transition: all 0.2s ease-in;
        }
    }
}

// mobile end