// .aaa {
//     width: 200px;
// }

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100px;
    z-index: 7;

    &__logo {
        position: relative;
        margin-left: 2.5vw;
        width: 150px;
        height: 100%;
        z-index: 4;


        & svg {
            position: absolute;
            width: 100%;
            height: 100%;
            transition: all 0.4s;
            left: 0;
            transition: color 0.2s;
            fill: var(--color-white);
            transition: fill 0.2s;
        }
    }

    &__links {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 2.5vw;
    }

    &__link-instagram {
        z-index: 4;

        & svg {
            fill: var(--color-white);
            transition: fill 0.2s;
        }
    }

    &__link-contactUs {
        z-index: 4;
        margin-left: 20px;
        margin-right: 100px;
        font-size: var(--font-size-lead-text);
        color: var(--color-white);
        text-decoration: none;
        transition: color 0.2s;
    }

    &__burger-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50px;
        height: 25px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        z-index: 4;

        &::before {
            content: "";
            width: 50px;
            height: 3px;
            background-color: var(--color-white);
            transition: transform 0.2s linear 0s;
        }

        &::after {
            content: "";
            width: 50px;
            height: 3px;
            background-color: var(--color-white);
            transition: transform 0.2s linear 0s;
        }

        &--black {
            &::after {
                background-color: #626262 !important;
            }
            &::before {
                background-color: #626262 !important;
            }
        }

        &_active {
            &:before{
                transform: rotate(-45deg) scaleX(1) translate(-7px, 7px);
                transition: transform 0.2s linear 0s;
            }
    
            &:after{
                transform: rotate(45deg) scaleX(1) translate(-7px, -7px);
                transition: transform 0.2s linear 0s;
            }
        }
    }

    &__nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0;
        width: 100vw;
        height: 100vh;
        background: linear-gradient(90deg, rgba(10,52,105,1) 0%, rgba(97,63,134,1) 50%, rgba(181,82,82,1) 100%);
        // background: linear-gradient(90deg, var(--color-blue) 0%,  rgba(97,63,134,1) 50%, var(--color-watermelon) 100%);
        list-style: none;
        transform: translateY(-100vh);

        &_show {
            animation-name: headerNavAnimationShow;
            animation-duration: 0.5s;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
        }

        @keyframes headerNavAnimationShow {
            from {
                transform: translateY(-100vh);
            }
            to {
                transform: translateY(0);
            }
        }
        &_hide {
            animation-name: headerNavAnimationHide;
            animation-duration: 0.5s;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
        }
        
        @keyframes headerNavAnimationHide {
            from {
                transform: translateY(0);
            }
            to {
                transform: translateY(-100vh);
            }
        }

        
    }
    
    &__nav-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__nav-link {
        font-size: var(--font-size-main);
        color: var(--color-white);
        text-decoration: none;
    }

    &__nav-link--active {
        text-decoration: line-through;
        // color: var(--color-active);
        cursor: default;

        &:hover {
            color: var(--color-white) !important;
        }
    }
    &__nav-link:hover {
        color: #FF4D5A;
        transition: color 0.2s linear 0s;
    }
}

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {
    
    .header {

        &__logo {
            width: 150px;
            height: 100%;
            margin-left: 3vw;
    
            svg {
                width: 100%;
                height: 100%;
                fill: #fff;
            }
        }

        &__links {
            margin-right: 3vw;
        }

        &__link-contactUs {
            margin-right: 80px;
        }
    }
}

@media (min-width: 769px) and (max-width: 992px) {

    .header {

        &__logo {
            width: 120px;
            height: 100%;
            margin-left: 3vw;
    
            svg {
                width: 100%;
                height: 100%;
                fill: #fff;
            }
        }

        &__links {
            margin-right: 3vw;
        }

        &__link-contactUs {
            margin-left: 20px;
            margin-right: 60px;
        }
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .header {
        height: 60px;

        &__logo {
            width: 100px;
            height: 100%;
            margin-left: 4vw;
    
            svg {
                width: 100%;
                height: 100%;
                fill: #fff;
            }
        }

        &__links {
            margin-right: 4vw;
        }

        &__link-instagram {
            display: none;
        }
    
        &__link-contactUs {
            display: none;
        }

        &__burger-menu {
            width: 30px;
            height: 15px;
    
            &::before {
                width: 30px;
                height: 3px;
            }
    
            &::after {
                width: 30px;
                height: 3px;
            }

            &_active {
                &:before{
                    transform: rotate(-45deg) scaleX(1) translate(-4px, 4px);
                }
        
                &:after{
                    transform: rotate(45deg) scaleX(1) translate(-4px, -4px);
                }
            }
        }
    }
}
  
@media (min-width: 0px) and (max-width: 576px) {

    .header {
        height: 60px;

        &__logo {
            width: 22%;
            height: 100%;
            margin-left: 4vw;
    
            svg {
                width: 100%;
                height: 100%;
                fill: #fff;
            }
        }

        &__links {
            margin-right: 4vw;
        }

        &__link-instagram {
            display: none;
        }
    
        &__link-contactUs {
            display: none;
        }

        &__burger-menu {
            width: 30px;
            height: 15px;
    
            &::before {
                width: 30px;
                height: 3px;
            }
    
            &::after {
                width: 30px;
                height: 3px;
            }

            &_active {
                &:before{
                    transform: rotate(-45deg) scaleX(1) translate(-4px, 4px);
                }
        
                &:after{
                    transform: rotate(45deg) scaleX(1) translate(-4px, -4px);
                }
            }
        }
    }
}

// mobile end