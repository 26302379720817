.sky {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(2,11,22,1) 0%, rgba(7,33,66,1) 100%);
}

.bg-block {
    position: fixed;
    right: 0;
    top:0;
    width: 70%;
    height: 100vh;
    // background-color: grey;
}

.stars {
    width: 70%;
    height: 100%;
    background-image: url('../../media/images/welcomePage/star.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.9;
}

.glow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-image: url('../../media/images/welcomePage/glow.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.main-slide {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.cloud-block {
    position: fixed;
    display: none;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 70%;
    height: 100vh;
    overflow: hidden;
}

.text-portfolio {
    margin-right: 30%;
    font-size: var(--font-size2);
    font-weight: bold;
    color: var(--color-watermelon);
    text-align: center;
}

.moon {
    width: 35%;
    padding-top: 35%;
    background-image: url('../../media/images/welcomePage/moon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
}


.cloud-block-top {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 50%;
}
.cloud-block-bottom {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 50%;
}
.cloud {
    // position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.cloud1 {
    width: 25%;
    padding-top: 25%;
    padding-right: 18%;
    padding-bottom: 2%;
    background-image: url('../../media/images/welcomePage/gCloud.png');
}
.cloud2 {
    width: 28%;
    padding-top: 28%;
    padding-left: 10%;
    padding-bottom: 10%;
    background-image: url('../../media/images/welcomePage/gCloud.png');
}
.cloud3 {
    width: 30%;
    padding-top: 30%;
    background-image: url('../../media/images/welcomePage/bCloud.png');
}
.cloud4 {
    width: 35%;
    padding-top: 35%;
    margin-right: -15%;
    margin-top: -10%;
    background-image: url('../../media/images/welcomePage/bCloud.png');
}

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {
}

@media (min-width: 769px) and (max-width: 992px) {
}

@media (min-width: 577px) and (max-width: 768px) {
    .bg-block {
        width: 100%;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
    }
    .stars {
        width: 100%;
        height: 60%;
    }

    .glow {
        height: 60vh;
    }

    .cloud-block {
        width: 100%;
        height: 50vh;
    }

    .cloud1 {
        padding: 30px;
    }
    .cloud2 {
        padding: 40px;
    }
}
  
@media (min-width: 0px) and (max-width: 576px) {

    .bg-block {
        width: 100%;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
    }
    .stars {
        width: 100%;
        height: 60%;
    }

    .glow {
        height: 60vh;
    }

    .cloud-block {
        width: 100%;
        height: 50vh;
    }

    .cloud1 {
        padding: 30px;
    }
    .cloud2 {
        padding: 40px;
    }
}

// mobile end