.menu-bar {
    display: flex;
    align-items: center;
    height: 100vh;
    z-index: 2;
    position: fixed;
    left: 2.5vw;
    overflow: hidden;

    &__list {
        height: 70%;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {
    .menu-bar {
        justify-content: center;
        height: min-content;
        width: 100vw;
        left: 0;
        right: 0;
        bottom: 32px;

        &__list {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            padding: 0;
        }
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .menu-bar {
        justify-content: center;
        height: min-content;
        width: 100vw;
        left: 0;
        right: 0;
        bottom: 32px;

        &__list {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            padding: 0;
        }
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .menu-bar {
        justify-content: center;
        height: min-content;
        width: 100vw;
        left: 0;
        right: 0;
        bottom: 24px;

        &__list {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            padding: 0;
        }
    }
}
  
@media (min-width: 0px) and (max-width: 576px) {
    .menu-bar {
        justify-content: center;
        height: 36px;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 1%;

        &__list {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            padding: 0;
        }
    }
}

// mobile end