.spinner {
    z-index: 5;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(2,11,22,1) 0%, rgba(7,33,66,1) 100%);

    &__wrapper{
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    &__loaderBlock{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        margin: 15px;
        & svg{
            width: 100%;
            height: 100%;
            fill: rgba($color: white, $alpha: .2);
            animation: StrokeAnim 1s ease-in-out infinite alternate;
        }
    }


    @keyframes StrokeAnim {
        from {
            fill: rgba($color: white, $alpha: .2);
            filter: drop-shadow(0 0 5px #ff4d5a);
            transform: scale(.98);
        }
        to {
            fill: rgba($color: white, $alpha: 1);
            filter: drop-shadow(0 0 15px #ff4d5a);
            transform: scale(1);
        }

    }

}    