

.back-arrow {
    position: fixed;
    top: 16vh;
    left: var(--menu-items-offset);
    display: block;
    width: 64px;
    height: 40px;
    overflow: visible;
    // overflow: hidden;
    z-index: 3;
    border: 0;

    svg {
        transition: transform 0.3s ease 0s;
    }

    &--color-black {
        color: #000 !important;
        fill: #000 !important;
    }
}

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {
    .back-arrow {
        width: 4vw;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .back-arrow {
        width: 4vw;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .back-arrow {
        width: 8vw;
        top: 10vh;
    }
}
  
@media (min-width: 0px) and (max-width: 576px) {

    .back-arrow {
        width: 8vw;
        top: 12vh;
    }
}

// mobile end