* {
  margin: 0;  
  padding: 0;
  font-size: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
  // background: linear-gradient(90deg, rgba(2,11,22,1) 0%, rgba(7,33,66,1) 100%);
  background-color: #020b16;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  background: linear-gradient(90deg, rgba(2,11,22,1) 0%, rgba(7,33,66,1) 100%);
}

:root {

  // fonts
  --font-size-main: 96px;
  --font-size1: 72px;
  --font-size2: 48px;
  --font-size3: 36px;
  --font-size4: 28px;
  --font-size-lead-text: 24px;
  --font-size-small-text: 18px;
  --font-size-btn-text: 18px;

  // margins and paddings
  --outer-horizontal-offset: 8vw;
  --internal-horizontal-offset: 4vw;
  --internal-horizontal-offset-reverse: -4vw;
  --menu-items-offset: 2.5vw;

  // colors
  --color-black: #000;
  --color-white: #fff;
  --color-watermelon: #FD4659;
  --color-grey: #7D8FA5;
  --color-blue: #072142;

  // static pages animations

  --transition-on-scroll: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

// mobile start

@media (min-width: 993px) and (max-width: 1200px) {

  :root {
    --font-size-main: 80px;
    --font-size1: 60px;
    --font-size2: 40px;
    --font-size3: 30px;
    --font-size3: 24px;
    --font-size-lead-text: 20px;
    --font-size-small-text: 14px;
    --font-size-btn-text: 18px;
    
    --outer-horizontal-offset: 8vw;
    --internal-horizontal-offset: 4vw;
    --menu-items-offset: 2vw;
  }
}



@media (min-width: 769px) and (max-width: 992px) {

  :root {
    --font-size-main: 66px;
    --font-size1: 54px;
    --font-size2: 36px;
    --font-size3: 27px;
    --font-size4: 22px;
    --font-size-lead-text: 18px;
    --font-size-small-text: 14px;
    --font-size-btn-text: 18px;
    
    --outer-horizontal-offset: 8vw;
    --internal-horizontal-offset: 4vw;
    --menu-items-offset: 2vw;
  }
}

@media (min-width: 577px) and (max-width: 768px) {

  :root {
    --font-size-main: 48px;
    --font-size1: 36px;
    --font-size2: 28px;
    --font-size3: 26px;
    --font-size4: 24px;
    --font-size-lead-text: 14px;
    --font-size-small-text: 12px;
    --font-size-btn-text: 14px;

    --font-size-btn-text: 18px;
    --outer-horizontal-offset: 8%;
    --internal-horizontal-offset: 4%;
    --menu-items-offset: 2vw;
  }
}

@media (min-width: 0px) and (max-width: 576px) {

  :root {
    --font-size-main: 48px;
    --font-size1: 36px;
    --font-size2: 28px;
    --font-size3: 26px;
    --font-size4: 24px;
    --font-size-lead-text: 16px;
    --font-size-small-text: 10px;
    --font-size-btn-text: 16px;

    --outer-horizontal-offset: 8%;
    --internal-horizontal-offset: 4%;
    --menu-items-offset: 2vw;
  }
}

// mobile end
